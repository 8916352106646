@import '../theme-bootstrap';

@mixin pyramid-grid-mpp-custom-layout($gutter: 0, $item: '.grid--item') {
  @include clearfix();
  #{$item} {
    float: #{$ldirection};
    width: 100%;
    min-height: 500px;
    @include breakpoint($bp--medium-up) {
      @include layout($grid--2, $gutter) {
        width: 47%;
      }
      max-height: unset;
    }
    @include breakpoint($bp--large-up) {
      @include layout($grid--3, $gutter) {
        width: calc(33.3333% - 10.6666px);
      }
      max-height: unset;
    }
  }
}

@if $cr21_mpp == true {
  .cr21-refresh {
    &.hotspot-product-formatter {
      .hotspot-product__card {
        .product-brief-v2 {
          .product-brief-shades__shade {
            height: 30px;
          }
          .slick-next,
          .slick-prev {
            top: 19px;
            @include breakpoint($bp--large-up) {
              top: 23px;
            }
          }
          .product-brief__ppu-price {
            text-align: center;
          }
        }
      }
    }
    &.mpp-responsive-container {
      &.mpp-custom-override-layout {
        max-width: 1440px;
        padding: 0 20px;
        &.is-slider {
          .mpp-custom__header {
            padding-block-end: 0;
            .at-h3__typography {
              @include title-01;
              text-align: $ldirection;
              @include breakpoint($bp--medium-up) {
                width: calc(100% - 128px);
              }
            }
          }
          .grid--mpp {
            padding-block-start: 0;
            margin-block-start: 70px;
            overflow: visible;
            .slick-list {
              width: 100%;
              margin: 0;
            }
            .slick-next,
            .slick-prev {
              top: -55px;
              &.slick-disabled {
                display: none;
              }
            }
            .slick-dots {
              li {
                margin: 0 2px;
                width: 8px;
                height: 8px;
                button {
                  width: 8px;
                  height: 8px;
                  &::before {
                    width: 6px;
                    height: 6px;
                    font-size: 6px;
                    opacity: 1;
                    border: 1px solid $color--black;
                    border-radius: 10px;
                    background-color: transparent;
                    color: transparent;
                  }
                }
                &.slick-active {
                  button::before {
                    background-color: $color--black;
                  }
                }
              }
            }
            .product-brief-shades {
              .slick-next,
              .slick-prev {
                top: 15px;
                @include breakpoint($bp--medium-up) {
                  top: 20px;
                }
              }
            }
          }
          .mpp-custom__header + .grid--mpp {
            margin-block-start: 0;
          }
        }
      }
    }
    .rectangle_wide {
      .block-template-split-width-text-image-v1 {
        display: flex;
        height: 100%;
        .node-elc-nodeblock,
        .content,
        .content-block--split-width {
          height: 100%;
        }
      }
    }
    // remove condition in ASMBLY3-1246
    @if $cr21-images == true {
      &.mpp-custom-override-layout {
        @include breakpoint($bp--large-up) {
          padding: 0 20px;
        }
        @include breakpoint($bp--xxlarge-up) {
          padding: 0;
        }
      }
    }
    .grid--mpp {
      &__custom-layout {
        @include pyramid-grid-mpp-custom-layout($gutter: 16, $item: '.grid--mpp__item');
        width: 1440px;
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        // remove condition in ASMBLY3-1246
        @if $cr21-images == true {
          grid-gap: 16px;
        }
        &.grid-layout--masonry {
          .grid--mpp__gutter-sizer {
            display: block;
          }
          .grid--mpp__item {
            @include breakpoint($bp--medium-up) {
              min-height: 780px;
            }
          }
        }
        &.grid-layout--mixItUp {
          &.mixitup-initialized {
            .grid--mpp__item {
              @include breakpoint($bp--small-up) {
                display: none;
              }
            }
          }
        }
        .grid--mpp__gutter-sizer {
          display: none;
          max-width: 16px;
        }
        .grid--mpp__item {
          position: relative;
          // remove condition in ASMBLY3-1246
          @if $cr21-images == true {
            margin-bottom: 0;
          }
          @include breakpoint($bp--small-up) {
            display: flex;
            // remove code in ASMBLY3-1246
            @if $cr21-images == false {
              margin-bottom: 50px;
              background-color: $color--white;
            }
          }
          &.rectangle_tall {
            z-index: 3;
          }
          // remove code in ASMBLY3-1246
          @if $cr21-images == false {
            &:nth-of-type(3n - 1),
            &.is-row-divider {
              &:before {
                @include breakpoint($bp--medium-up) {
                  content: '';
                  width: 520%;
                  position: absolute;
                  z-index: 2;
                  height: 1px;
                  border-top: 1px solid $color-cta-grey;
                  top: -30px;
                  #{$ldirection}: -210%;
                }
              }
            }
          }
          &.filterable-products__grid-item-tout {
            align-items: center;
            justify-content: center;
            // remove condition and code in else in ASMBLY3-1246
            @if $cr21-images == true {
              margin-bottom: 0;
            } @else {
              margin-bottom: 40px;
            }
            &.rectangle_wide {
              flex: 50%;
              @include breakpoint($bp--medium-up) {
                max-width: calc(66.6666% - 5px);
              }
            }
            // remove condition in ASMBLY3-1246
            @if $cr21-images == true {
              img {
                width: 100%;
              }
            }
          }
        }
      }
      &.active-model {
        .product-brief__badge {
          color: $color--black;
        }
      }
    }
    .node-elc-mpp.no-sidebars {
      .field-elc-mpp-content {
        @include breakpoint($bp--large-up) {
          .mpp-sort__container {
            div.mpp-sort-menu {
              min-width: unset;
              border-radius: 30px;
            }
          }
        }
      }
    }
    .filters_container {
      // remove code in ASMBLY3-1246
      @if $cr21-images == false {
        width: 1352px;
      }
      max-width: 100%;
      margin: 0 auto;
      padding-top: 0;
      @include breakpoint($bp--large-up) {
        padding-top: 10px;
        height: 65px;
      }
      &.expanded {
        @include breakpoint($bp--large-up) {
          margin: 0 auto;
          min-height: 120px;
        }
      }
      &.filtered {
        @include breakpoint($bp--large-up) {
          margin: 0 auto;
        }
      }
      &--inner {
        max-width: 100%;
        @include breakpoint($bp--xlarge-up) {
          justify-content: space-between;
        }
      }
      .filterable-products {
        &__filter-set-container--inner {
          position: relative;
          .vertical-line {
            padding-#{$rdirection}: 9px;
            @include breakpoint($bp--large-up) {
              padding-#{$rdirection}: 20px;
            }
          }
        }
        &__filter-set-label {
          @include body-text--small-bold;
          color: $color--black;
          background-color: $color--white;
          border: 1px solid $color--gray--lighter;
          border-radius: 30px;
          line-height: 3.4;
          @include breakpoint($bp--large-up) {
            border: none;
            line-height: get-line-height(12px, 14px);
          }
        }
        &__filter-arrow:before {
          border-color: $color--black;
        }
        &__wrapper {
          .filterable-products__filter-set-section {
            &.current {
              .filterable-products-filter-set__filters {
                @include breakpoint($bp--large-up) {
                  justify-content: flex-start;
                }
              }
            }
            .filterable-products-filter-set__filters {
              .filterable-products-filter-set__button {
                padding: 0 12px 0 12px;
              }
            }
            .filterable-products-filter-set__button-wrapper-label {
              @include body-text--small;
            }
            .filterable-products-filter-set__title {
              @include body-text--small-bold;
            }
            .filterable-products-filter-set__button[data-filter-value='red_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-red-shade,
                  $color-red-shade,
                  $color-red-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox($color-red-shade, $color-red-shade, $color--white);
              }
            }
            .filterable-products-filter-set__button[data-filter-value='pink_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-pink-shade,
                  $color-pink-shade,
                  $color-pink-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-pink-shade,
                  $color-pink-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='orange_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-orange-shade,
                  $color-orange-shade,
                  $color-orange-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-orange-shade,
                  $color-orange-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='nude_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-nude-shade,
                  $color-nude-shade,
                  $color-nude-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-nude-shade,
                  $color-nude-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='berry_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-berry-shade,
                  $color-berry-shade,
                  $color-berry-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-berry-shade,
                  $color-berry-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='blue_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-blue-shade,
                  $color-blue-shade,
                  $color-blue-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-blue-shade,
                  $color-blue-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='purple_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-purple-shade,
                  $color-purple-shade,
                  $color-purple-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-purple-shade,
                  $color-purple-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='brown_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-brown-shade,
                  $color-brown-shade,
                  $color-brown-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-brown-shade,
                  $color-brown-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='coral_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-coral-shade,
                  $color-coral-shade,
                  $color-coral-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-coral-shade,
                  $color-coral-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='bold_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-bold-shade,
                  $color-bold-shade,
                  $color-bold-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-bold-shade,
                  $color-bold-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='beige_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-beige-shade,
                  $color-beige-shade,
                  $color-beige-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-beige-shade,
                  $color-beige-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='gold_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-gold-shade,
                  $color-gold-shade,
                  $color-gold-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-gold-shade,
                  $color-gold-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='white_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-white-shade,
                  $color-white-shade,
                  $color-white-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-white-shade,
                  $color-white-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='copper_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-copper-shade,
                  $color-copper-shade,
                  $color-copper-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-copper-shade,
                  $color-copper-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='neutral_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-neutral-shade,
                  $color-neutral-shade,
                  $color-neutral-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-neutral-shade,
                  $color-neutral-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='yellow_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-yellow-shade,
                  $color-yellow-shade,
                  $color-yellow-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-yellow-shade,
                  $color-yellow-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='bronze_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-bronze-shade,
                  $color-bronze-shade,
                  $color-bronze-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-bronze-shade,
                  $color-bronze-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='green_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-green-shade,
                  $color-green-shade,
                  $color-green-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-green-shade,
                  $color-green-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='silver_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-silver-shade,
                  $color-silver-shade,
                  $color-silver-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-silver-shade,
                  $color-silver-shade,
                  $color--white
                );
              }
            }
            .filterable-products-filter-set__button[data-filter-value='black_color'] {
              .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-black-shade,
                  $color-black-shade,
                  $color-black-shade
                );
              }
              &.active .filterable-products-filter-set__button-wrapper-label {
                @include paint-filters-checkbox(
                  $color-black-shade,
                  $color-black-shade,
                  $color--white
                );
              }
            }
            .active .filterable-products-filter-set__button-wrapper-label,
            .filterable-products-filter-set__button-wrapper-label {
              @include paint-filters-checkbox();
              &:before {
                margin-top: -3px;
                line-height: 14px;
              }
            }
            .active .filterable-products-filter-set__button-wrapper-label {
              @include icon(check, $pseudo: before);
              @include paint-filters-checkbox($bg: $color--black);
              &.hover {
                line-height: 25px;
              }
              &:before {
                padding: 0;
                margin-top: -2px;
                height: 12px;
                line-height: 13px;
                @include breakpoint($bp--medium-up) {
                  margin-top: -3px;
                  line-height: 12px;
                  font-size: 11.7px;
                }
              }
            }
            .filterable-products-filter-set__button {
              @include swap_direction(padding, 0 25px 0 0);
              line-height: 25px;
              &.hover {
                line-height: 25px;
              }
            }
            .filterable-products-filter-set__button-wrapper-label {
              text-transform: capitalize;
              margin-bottom: 25px;
              &:only-child {
                margin: 0 0 25px;
                @include breakpoint($bp--xlarge-up) {
                  padding: 0;
                }
              }
              &:before {
                content: '';
                padding: 1px;
                margin-#{$rdirection}: 10px;
                border-radius: 5px;
                padding: 0 6px;
                border: 2px solid $color--gray--lighter;
              }
              &:hover {
                &:before {
                  border: 2px solid $color--gray--lighter;
                }
              }
            }
          }
          .filterable-products-filter-set__button-wrapper-smoosh {
            display: none;
          }
          .filterable-products__filter-set-footer {
            border-radius: 20px;
            padding: 10px 15px;
            display: none;
            #{$rdirection}: 35%;
            z-index: 0;
            @include breakpoint($bp--medium-up) {
              #{$rdirection}: 44%;
            }
            @include breakpoint($bp--large-up) {
              top: -4px;
              #{$rdirection}: -100px;
              bottom: unset;
              padding: 5px 15px;
              background: $color--white;
              border: 1px solid $color-cta-grey;
            }
            .reset-link {
              font-family: $ano-bold_regular-font;
              text-transform: uppercase;
              color: $color--black;
              border-bottom-color: $color--gray--lighter;
              @include breakpoint($bp--large-up) {
                border: none;
                font-family: $ano_regular-font;
                text-transform: lowercase;
              }
            }
            .filterable-products_product-count {
              font-family: $ano-bold_regular-font;
              @include breakpoint($bp--large-up) {
                display: none;
              }
              span:first-child {
                margin-#{$rdirection}: 5px;
              }
              &:first-letter {
                text-transform: capitalize;
              }
            }
            .filterable-products_product-count {
              display: none;
            }
          }
        }
      }
      &.filtered {
        .filterable-products {
          &__wrapper {
            .filterable-products__filter-set-footer {
              display: flex;
              bottom: -63px;
              flex-direction: row-reverse;
              @include breakpoint($bp--xlarge-up) {
                bottom: unset;
              }
            }
          }
        }
        .mpp-model-images {
          &__container {
            @include breakpoint($bp--xlarge-up) {
              @include swap_direction(margin, -10px 0 20px 0);
            }
          }
        }
      }
      .no-visible-filters {
        &.filterable-products {
          &__wrapper {
            .filterable-products__filter-set-footer {
              bottom: -23px;
              @include breakpoint($bp--xlarge-up) {
                bottom: unset;
              }
            }
          }
        }
      }
      &.expanded {
        .filterable-products {
          &__wrapper {
            .filterable-products__filter-set-footer {
              bottom: -61px;
              @include breakpoint($bp--xlarge-up) {
                bottom: unset;
              }
            }
          }
        }
      }
      .mpp-model-images__container ~ .mpp-sort-wrapper {
        top: 85px;
        @include breakpoint($bp--xlarge-up) {
          order: 3;
          width: auto;
          top: 0;
        }
      }
      .mpp-model-images {
        &__container {
          height: 45px;
          border: 2px solid $color-cta-grey;
          border-radius: 30px;
          margin-bottom: 20px;
          max-width: 250px;
          order: 0;
          @include breakpoint($bp--largest-up) {
            width: auto;
            @include swap_direction(margin, -10px 0 20px 0);
          }
        }
        &__label {
          display: none;
        }
        &__option {
          @include body-text--small-bold;
          color: $color--black;
          border-color: $color--black;
          border: 0;
          border-radius: 30px;
          margin: 0;
          line-height: 1;
          height: 45px;
          @include breakpoint($bp--xlarge-up) {
            padding: 15px 30px;
            margin: 0;
          }
          &.selected {
            color: $color--white;
            background: $color--black;
            margin: -1px -1px -1px -1px;
            height: 43px;
          }
          &:first-child {
            &.selected {
              margin: -1px -10px -1px -1px;
            }
          }
          span {
            line-height: 3.5;
            @include breakpoint($bp--large-up) {
              line-height: inherit;
            }
          }
        }
      }
      .mpp-model-images__container ~ .filterable-products__wrapper {
        margin: 0 0 80px;
        @include breakpoint($bp--large-up) {
          margin: 0;
        }
        .filterable-products__filter-set-section {
          &.current {
            .filterable-products-filter-set__filters {
              @include breakpoint($bp--medium-up) {
                justify-content: space-evenly;
              }
            }
          }
        }
      }
      .filterable-products__wrapper {
        margin: 0;
      }
    }
    .product-brief-shades {
      &__shade {
        height: 30px;
        width: 30px;
        border-radius: 30px;
        margin-#{$rdirection}: 5px;
        &.low-contrast {
          @include low-contrast-shadow;
        }
        @include breakpoint($bp--medium-up) {
          height: 30px;
        }
        &.light-shade {
          border: 1px solid $color-cta-grey;
        }
        &.selected {
          box-shadow: none;
          border-radius: 30px;
          overflow: hidden;
          &.low-contrast {
            @include low-contrast-shadow;
          }
          &::after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: $color--white;
            border-radius: 8px;
            #{$ldirection}: 11px;
            top: 11px;
          }
          &:hover {
            &::before {
              box-shadow: none;
            }
          }
        }
      }
      &::before {
        content: none;
      }
      &__shade-item {
        width: 30px !important;
        margin-inline-end: 8px;
        &:last-of-type {
          margin-inline-end: 0;
        }
        @include breakpoint($bp--medium-up) {
          margin-inline-end: 0;
        }
        a {
          overflow: hidden;
        }
      }
      .slick-prev,
      .slick-next {
        top: 0;
        margin-block-start: -9px;
        @include breakpoint($bp--medium-up) {
          margin-block-start: -7px;
        }
        &.disable-arrow,
        img {
          display: none;
        }
        .icon {
          width: 20px;
          height: 20px;
          margin-block-start: 14px;
          @include breakpoint($bp--medium-up) {
            margin-block-start: 28px;
          }
        }
        background: unset;
      }
      .slick-next {
        // disable RTL, unsupported by slick
        left: unset;
        margin-inline-end: -39px;
        @include breakpoint($bp--medium-up) {
          margin-inline-end: -51px;
        }
        @if $text-direction == 'rtl' {
          #{$ldirection}: 0;
          margin-inline-start: -46px;
          @include breakpoint($bp--medium-up) {
            margin-inline-start: -51px;
          }
        }
      }
      .slick-next,
      .slick-next:before,
      .slick-prev,
      .slick-prev:before {
        border: 0;
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        width: 30px;
        height: 46px;
        min-width: unset;
        min-height: unset;
        @include breakpoint($bp--medium-up) {
          width: 34px;
          height: 54px;
        }
      }
      .slick-prev {
        // disable RTL, unsupported by slick
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        margin-inline-start: -45px;
        @include breakpoint($bp--medium-up) {
          margin-inline-start: -52px;
        }
        @if $text-direction == 'rtl' {
          #{$rdirection}: 0;
          margin-inline-end: -45px;
          @include breakpoint($bp--medium-up) {
            margin-inline-end: -52px;
          }
        }
      }
    }
    &:not[data-is-slider='1'] {
      .product-brief-v2 {
        max-width: unset;
      }
    }
    .product-brief-v2 {
      padding: 14px 20px;
      text-align: center;
      height: 100%;
      display: flex;
      flex-direction: column;
      // remove condition in ASMBLY3-1246
      @if $cr21-images == true {
        background: $color-cta-grey--light;
        max-width: unset;
      }
      .product-price-installment {
        color: $color--black;
      }
      .size-select ~ .product-price-installment {
        margin-block-start: 8px;
      }
      .product-brief__image-link {
        width: 100%;
        aspect-ratio: 1;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        img {
          width: 100%;
          height: auto;
        }
      }
      &[data-sku-inv-status='3'] {
        .product-brief__cta {
          &:hover {
            display: flex;
          }
        }
      }
      &:hover {
        .product-brief-shades__shade {
          &.light-shade {
            border: 1px solid $color-cta-grey;
            &:hover {
              &:before {
                margin-top: -5px;
              }
            }
          }
        }
      }
      .product-brief {
        &__notify-me {
          @include swap_direction(padding, 5px 3px 0 0);
          width: auto;
          margin: 0;
          z-index: 1;
          cursor: pointer;
          .notify-status {
            background: $color--black;
            border-radius: 50px;
            a {
              border: 0;
              color: $color--white;
              font-family: $ano-bold_regular-font;
              font-size: 12px;
              height: 45px;
              line-height: 45px;
              padding: 0 20px;
            }
          }
        }
        &__add-to-bag,
        &__link-to-spp {
          order: 1;
          padding-#{$rdirection}: 3px;
          max-width: 49.5%;
          align-self: flex-start;
          z-index: 1;
          a {
            border: 0 !important;
            max-width: unset;
            width: auto;
            padding: 0 20px;
            border-radius: 45px;
            height: 45px;
            font-size: 12px;
            font-family: $ano-bold_regular-font;
            line-height: 45px;
          }
        }
        &__container {
          border: 0;
          z-index: 1;
        }
        &__top {
          width: 100%;
          z-index: 2;
        }
        &__image {
          height: auto;
          border: 0;
          display: flex;
          width: calc(100% + 40px);
          position: relative;
          margin-#{$ldirection}: -20px;
          z-index: 1;
          top: -15px;
          @include breakpoint($bp--xlarge-up) {
            height: auto;
          }
          @include breakpoint($bp--xxlarge-up) {
            height: auto;
          }
          &-img {
            max-height: unset;
            @include breakpoint($bp--xlarge-up) {
              max-height: unset;
            }
            @include breakpoint($bp--xxlarge-up) {
              max-height: unset;
            }
            &--alt-image {
              #{$ldirection}: unset;
              top: unset;
            }
          }
        }
        &__rating {
          .product__rating-stars {
            height: 15px;
            width: 80px;
            @include breakpoint($bp--large-up) {
              height: 20px;
              width: 90px;
            }
            &-rating {
              width: 80px;
              @include breakpoint($bp--large-up) {
                width: 90px;
              }
            }
          }
          &-count {
            @include body-text--small;
            margin-#{$ldirection}: 7px;
          }
        }
        &__shades {
          margin: 0 0 7px;
          .product-brief-shades {
            min-height: unset;
            max-width: 226px;
            margin: auto;
            position: relative;
            @include breakpoint($bp--medium-up) {
              max-width: 210px;
            }
          }
        }
        // remove condition in ASMBLY3-1246
        @if $cr21-images == true {
          &__rating {
            #{$ldirection}: 0;
            #{$rdirection}: unset;
          }
        }
        &__badge {
          @include body-text--small-bold;
          border: 1px solid $color-cta-grey;
          padding: 2px 5px;
          border-radius: 10px;
          background-color: $color--white;
          // remove condition in ASMBLY3-1246
          @if $cr21-images == true {
            #{$rdirection}: 0;
          }
          @include breakpoint($bp--xlarge-up) {
            font-size: 12px;
            white-space: nowrap;
          }
        }
        &__details {
          min-height: unset;
          padding: 0 15px;
          max-width: 380px;
          margin: 0 auto;
        }
        &__name-link {
          @include breakpoint($bp--medium-up) {
            min-height: 46px;
          }
        }
        &__shade {
          font-family: $product-shade-name;
          bottom: 68px;
          margin: 0 auto;
          font-size: 14px;
          &.light-shade {
            color: $color-cta-grey !important;
          }
        }
        &__product-name {
          @include body-text--bold;
          font-family: $product-display-name;
          color: $color--black;
          min-width: 100%;
          line-height: get-line-height(16px, 18px);
          margin: 5px 0;
        }
        &__description {
          margin-bottom: 10px;
          @include breakpoint($bp--large-up) {
            min-height: unset;
          }
        }
        &__description,
        &__price {
          @include body-text;
          &-details {
            margin: 0 0 10px;
          }
          .product-price-v1 {
            align-self: center;
          }
          .product-price {
            font-family: inherit;
          }
        }
        &__tax-legal {
          width: 100%;
          float: unset;
        }
        &__cta {
          display: flex;
          justify-content: center;
          // remove condition and code from else in ASMBLY3-1246
          @if $cr21-images == true {
            margin: 15px 0;
          } @else {
            margin: 15px 0 0;
          }
          top: unset;
          flex-direction: row;
          flex-wrap: wrap;
          @include breakpoint($bp--medium-up) {
            min-height: unset;
          }
          .product-vto-link {
            float: none;
            margin-#{$ldirection}: unset;
            max-width: 49.5%;
            padding-#{$ldirection}: 3px;
            order: 2;
            display: flex;
            justify-content: flex-end;
            background: transparent;
            width: auto;
            @if $product-brief-2-rows-cta == true {
              margin-top: 3px;
              align-items: flex-end;
            }
            .cta-vto-link {
              @include cta-primary-outline;
              width: auto;
              min-width: 120px;
              background: inherit;
              &:hover {
                border-color: $color-cta-grey;
              }
            }
          }
          .product-brief__inventory-status {
            clear: none;
            order: 2;
            width: auto;
            padding-top: 4px;
            @if $product-brief-2-rows-cta == true {
              padding-top: 0;
            }
            .product-inventory-status__list {
              order: 2;
              border-radius: 45px;
              width: auto;
              margin-#{$ldirection}: 2px;
              .product-inventory-status {
                &__coming-soon-text,
                &__sold-out-text,
                &__temp-oos-short-text,
                &__item--inactive {
                  font-family: $ano-bold_regular-font;
                  line-height: 45px;
                }
                &__temp-oos-short-text {
                  display: none;
                  // keeping these next 2 just in case we need to enable this again
                  background: inherit;
                  line-height: 20px;
                }
                &__temp-oos-text,
                &__item--preorder-noshop {
                  text-transform: initial;
                  min-width: 320px;
                  color: $color--tos-red;
                }
              }
            }
          }
        }
        &__early-access {
          @include body-text--small;
        }
        &__footer {
          z-index: 2;
          // remove condition in ASMBLY3-1246
          @if $cr21-images == true {
            margin-bottom: 0;
            padding-bottom: 0;
            margin-top: unset;
            min-height: unset;
          }
          @include breakpoint($bp--medium-up) {
            margin-bottom: 0;
            padding-bottom: 0;
            margin-top: unset;
            min-height: unset;
          }
        }
        &-cta,
        .product-add-to-bag {
          @include cta-primary-filled;
          background-color: transparent;
          font-family: $ano-bold_regular-font;
        }
      }
    }
    .product-inventory-status {
      &__item,
      &--limited-remaining {
        @include body-text--small;
        text-align: center;
      }
      &__list {
        justify-content: center;
        line-height: 4;
        min-width: 120px;
      }
    }
  }
  .basic-responsive-v1 {
    p {
      @include body-text;
      font-size: 15px;
      margin-bottom: 5px;
      @include breakpoint($bp--medium-up) {
        margin-bottom: 22px;
      }
    }
    span {
      font-family: $ano_regular-font !important;
      font-size: 15px;
    }
    a:not(.chat--icon) {
      border: 0 !important;
      max-width: unset;
      width: auto !important;
      padding: 0 20px;
      border-radius: 45px;
      height: 45px;
      font-size: 12px;
      font-family: $ano-bold_regular-font !important;
      line-height: 45px;
    }
  }
  .tout-block-landscape {
    &__caption {
      a {
        border: 0 !important;
        max-width: unset;
        width: auto !important;
        padding: 0 20px;
        border-radius: 45px;
        height: 45px;
        font-size: 12px;
        font-family: $ano-bold_regular-font !important;
        line-height: 45px;
        @include breakpoint($bp--large-up) {
          margin: 0 !important;
        }
      }
    }
    &__body-above {
      span:first-of-type {
        @include breakpoint($bp--large-up) {
          font-size: 40px !important;
        }
        @include breakpoint($bp--xxlarge-up) {
          font-size: 60px !important;
        }
      }
      span:last-of-type {
        font-family: $ano_regular-font !important;
      }
    }
  }
}
@if $product-brief-2-rows-cta == true {
  .product-brief-v2 {
    .product-brief__footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .product-brief__cta {
        align-items: end;
      }
      .product-brief__notify-me {
        align-self: flex-end;
        padding: 4px 0 3px;
      }
      .product-brief__add-to-bag {
        max-width: unset;
        align-self: flex-end;
      }
      .product-inventory-status__temp-oos-text {
        margin-top: 0;
      }
    }
  }
}
